<script setup lang="ts">
const email = 'contact@synergos.app'

// Handle email click for analytics tracking if needed
const handleEmailClick = () => {
  // GTM tracking could be added here if required
}
</script>

<template>
  <footer class="bg-gray-900 text-white py-4 sm:py-6">
    <div class="max-w-screen-xl mx-auto px-4">
      <div class="flex flex-col sm:flex-row sm:justify-between items-center sm:items-baseline space-y-2 sm:space-y-0">
        <p class="text-sm text-gray-300">
          Copyright © {{ new Date().getFullYear() }}. All rights reserved.
        </p>
        <div class="flex items-center space-x-4">
          <NuxtLink to="/privacy" class="text-sm text-gray-300 hover:text-white transition-colors duration-200">
            Privacy Policy
          </NuxtLink>
          <a :href="`mailto:${email}`" class="text-sm text-gray-300 hover:text-white transition-colors duration-200"
            @click="handleEmailClick" aria-label="Contact us via email">
            {{ email }}
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
