
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as blogQjkfzJExZ7Meta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/blog.vue?macro=true";
import { default as checkoutSuccessVUlCeW6GTmMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/checkoutSuccess.vue?macro=true";
import { default as contact35OvJIp9riMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/contact.vue?macro=true";
import { default as checkoutGtxUiBnqHZMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/dashboard/checkout.vue?macro=true";
import { default as createConsumerGyt97SAQocMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/dashboard/createConsumer.vue?macro=true";
import { default as createSessionf2UVYbhLYAMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/dashboard/createSession.vue?macro=true";
import { default as homeXYSOEFjUt7Meta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/dashboard/home.vue?macro=true";
import { default as sessionsnD4yRw7BvkMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/dashboard/sessions.vue?macro=true";
import { default as dashboardVFseFvuHoaMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/dashboard.vue?macro=true";
import { default as _91sessionId_93gJbYtu0sQLMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/experience/[sessionId].vue?macro=true";
import { default as end6esKeu3Ks6Meta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/experience/end.vue?macro=true";
import { default as _91_46_46_46slug_93qfNgCNiidDMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/experience/live/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_932cKjhWV9e6Meta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/experience/wait/[...slug].vue?macro=true";
import { default as indexzCCGyQBCpyMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/index.vue?macro=true";
import { default as loginpzHimNnTAVMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/login.vue?macro=true";
import { default as pricingFR6VekruGWMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/pricing.vue?macro=true";
import { default as privacy1qAovqDSTiMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/privacy.vue?macro=true";
import { default as _91token_93QjnXc908NvMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/reset-password/[token].vue?macro=true";
import { default as signupmE1x5Xx7TqMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/signup.vue?macro=true";
import { default as systemChecklNB5Tcl8xxMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/systemCheck.vue?macro=true";
import { default as termsJpzWl9WIkyMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/terms.vue?macro=true";
import { default as _91_46_46_46slug_93UlZM7Jk5aNMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/pages/verify/[...slug].vue?macro=true";
import { default as component_45stubJbKhTtWE4yMeta } from "/codebuild/output/src3996839855/src/synergos-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubJbKhTtWE4y } from "/codebuild/output/src3996839855/src/synergos-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "blog",
    path: "/blog",
    meta: blogQjkfzJExZ7Meta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/blog.vue")
  },
  {
    name: "checkoutSuccess",
    path: "/checkoutSuccess",
    meta: checkoutSuccessVUlCeW6GTmMeta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/checkoutSuccess.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contact35OvJIp9riMeta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/contact.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardVFseFvuHoaMeta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-checkout",
    path: "checkout",
    meta: checkoutGtxUiBnqHZMeta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/dashboard/checkout.vue")
  },
  {
    name: "dashboard-createConsumer",
    path: "createConsumer",
    meta: createConsumerGyt97SAQocMeta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/dashboard/createConsumer.vue")
  },
  {
    name: "dashboard-createSession",
    path: "createSession",
    meta: createSessionf2UVYbhLYAMeta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/dashboard/createSession.vue")
  },
  {
    name: "dashboard-home",
    path: "home",
    meta: homeXYSOEFjUt7Meta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/dashboard/home.vue")
  },
  {
    name: "dashboard-sessions",
    path: "sessions",
    meta: sessionsnD4yRw7BvkMeta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/dashboard/sessions.vue")
  }
]
  },
  {
    name: "experience-sessionId",
    path: "/experience/:sessionId()",
    meta: _91sessionId_93gJbYtu0sQLMeta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/experience/[sessionId].vue")
  },
  {
    name: "experience-end",
    path: "/experience/end",
    meta: end6esKeu3Ks6Meta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/experience/end.vue")
  },
  {
    name: "experience-live-slug",
    path: "/experience/live/:slug(.*)*",
    meta: _91_46_46_46slug_93qfNgCNiidDMeta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/experience/live/[...slug].vue")
  },
  {
    name: "experience-wait-slug",
    path: "/experience/wait/:slug(.*)*",
    meta: _91_46_46_46slug_932cKjhWV9e6Meta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/experience/wait/[...slug].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexzCCGyQBCpyMeta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginpzHimNnTAVMeta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/login.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingFR6VekruGWMeta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/pricing.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/privacy.vue")
  },
  {
    name: "reset-password-token",
    path: "/reset-password/:token()",
    meta: _91token_93QjnXc908NvMeta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/reset-password/[token].vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupmE1x5Xx7TqMeta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/signup.vue")
  },
  {
    name: "systemCheck",
    path: "/systemCheck",
    meta: systemChecklNB5Tcl8xxMeta || {},
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/systemCheck.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/terms.vue")
  },
  {
    name: "verify-slug",
    path: "/verify/:slug(.*)*",
    component: () => import("/codebuild/output/src3996839855/src/synergos-frontend/pages/verify/[...slug].vue")
  },
  {
    name: component_45stubJbKhTtWE4yMeta?.name,
    path: "/docs",
    component: component_45stubJbKhTtWE4y
  }
]