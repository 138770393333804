import { ref } from 'vue'

interface CsrfState {
  promise: Promise<any> | null
  lastFetchTime: number
}

// Declare state outside composable to share across instances
const state: CsrfState = {
  promise: null,
  lastFetchTime: 0
}

export const useCsrfToken = () => {
  const CSRF_TOKEN_LIFETIME = 2 * 60 * 1000 // 2 minutes lifetime

  const fetchCsrfTokenIfNeeded = async () => {
    const now = Date.now()
    const tokenCookie = useCookie('XSRF-TOKEN')
    
    // Return if we have a valid token that's not expired
    if (
      tokenCookie.value && 
      now - state.lastFetchTime < CSRF_TOKEN_LIFETIME
    ) {
      return
    }

    // Return existing promise if there's an ongoing request
    if (state.promise) {
      return state.promise
    }

    // Create a new promise for token fetching
    state.promise = useApiFetch('/sanctum/csrf-cookie', {
      credentials: 'include'
    }).finally(() => {
      state.lastFetchTime = Date.now()
      state.promise = null
    })

    try {
      await state.promise
    } catch (error) {
      // Reset state on error
      state.lastFetchTime = 0
      state.promise = null
      // Implement exponential backoff
      await new Promise(resolve => setTimeout(resolve, 1000))
      // Retry once
      return fetchCsrfTokenIfNeeded()
    }
  }

  return {
    fetchCsrfTokenIfNeeded
  }
} 