import routerOptions0 from "/codebuild/output/src1489521149/src/synergos-frontend/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/codebuild/output/src1489521149/src/synergos-frontend/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}