import Ably from 'ably'

export default defineNuxtPlugin(nuxtApp => {
  // Only initialize Ably for experience-related routes and system check
  const route = useRoute()
  const isAllowedRoute = route.path.startsWith('/experience') || route.path === '/systemCheck'

  if (!isAllowedRoute) {
    return
  }

  const config = useRuntimeConfig()
  const ably = new Ably.Realtime({
    key: config.public.ABLY_API_KEY,
    clientId: 'nuxt-app', //optional
  })
  nuxtApp.provide('ably', ably)
})