// Define the type for the resource hint options
interface ResourceHintOptions {
  type?: 'preconnect' | 'dns-prefetch';
  crossorigin?: boolean;
}

// Declare the module augmentation for Nuxt
declare module '#app' {
  interface NuxtApp {
    $addResourceHint: (url: string, options?: ResourceHintOptions) => void;
  }
}

/**
 * Adds resource hints (preconnect and dns-prefetch) to optimize resource loading
 * 
 * @param url - The URL to preconnect and prefetch DNS for
 * 
 * Resource hints help improve performance by:
 * - preconnect: Establishes early connections to important third-party origins
 * - dns-prefetch: Resolves DNS for specified domains ahead of time
 */
export default defineNuxtPlugin(() => {
  const addResourceHint = (url: string, options: ResourceHintOptions = {}) => {
    // Only run on client-side since document is not available on server
    if (process.client) {
      const type = options.type || 'preconnect';
      const link = document.createElement('link');
      link.rel = type;
      link.href = url;
      if (options.crossorigin) {
        link.crossOrigin = 'anonymous';
      }
      document.head.appendChild(link);
      
      // If preconnect is requested, also add dns-prefetch as fallback
      if (type === 'preconnect') {
        const dnsPrefetchLink = document.createElement('link');
        dnsPrefetchLink.rel = 'dns-prefetch';
        dnsPrefetchLink.href = url;
        document.head.appendChild(dnsPrefetchLink);
      }
    }
  };

  // Add resource hints for Google Tag Manager
  addResourceHint('https://www.googletagmanager.com', { crossorigin: true });

  return {
    provide: {
      addResourceHint
    }
  };
});
