
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as blogbrsNW6zdS0Meta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/blog.vue?macro=true";
import { default as checkoutSuccesswYkiZRrGpPMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/checkoutSuccess.vue?macro=true";
import { default as contactHR4FldSJRfMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/contact.vue?macro=true";
import { default as checkoutnwBztFB5eDMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/dashboard/checkout.vue?macro=true";
import { default as createConsumercLTYapHK4qMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/dashboard/createConsumer.vue?macro=true";
import { default as createSessioniA85SgEpGGMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/dashboard/createSession.vue?macro=true";
import { default as home7laHID8UBJMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/dashboard/home.vue?macro=true";
import { default as sessionsqPhjnZecWeMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/dashboard/sessions.vue?macro=true";
import { default as dashboardLBVXVIerdyMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/dashboard.vue?macro=true";
import { default as _91sessionId_93mzQM8YYZGpMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/experience/[sessionId].vue?macro=true";
import { default as endGb3hQ1WSeiMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/experience/end.vue?macro=true";
import { default as _91_46_46_46slug_93rbsNfDxkF0Meta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/experience/live/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93300SOODkVKMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/experience/wait/[...slug].vue?macro=true";
import { default as indexTSAioG59l7Meta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/index.vue?macro=true";
import { default as loginIqreHEt0VhMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/login.vue?macro=true";
import { default as pricingGiP6x1aXBaMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/pricing.vue?macro=true";
import { default as privacyvjEJxiZWsnMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/privacy.vue?macro=true";
import { default as _91token_93qcLIpYXwdxMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/reset-password/[token].vue?macro=true";
import { default as signupz4EzOcAeGxMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/signup.vue?macro=true";
import { default as systemCheckOeanhVxuDKMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/systemCheck.vue?macro=true";
import { default as termscsFsYlmxv8Meta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/terms.vue?macro=true";
import { default as _91_46_46_46slug_930BgmWome9vMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/pages/verify/[...slug].vue?macro=true";
import { default as component_45stubYMP1BP7bQhMeta } from "/codebuild/output/src1489521149/src/synergos-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubYMP1BP7bQh } from "/codebuild/output/src1489521149/src/synergos-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "blog",
    path: "/blog",
    meta: blogbrsNW6zdS0Meta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/blog.vue")
  },
  {
    name: "checkoutSuccess",
    path: "/checkoutSuccess",
    meta: checkoutSuccesswYkiZRrGpPMeta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/checkoutSuccess.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactHR4FldSJRfMeta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/contact.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardLBVXVIerdyMeta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-checkout",
    path: "checkout",
    meta: checkoutnwBztFB5eDMeta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/dashboard/checkout.vue")
  },
  {
    name: "dashboard-createConsumer",
    path: "createConsumer",
    meta: createConsumercLTYapHK4qMeta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/dashboard/createConsumer.vue")
  },
  {
    name: "dashboard-createSession",
    path: "createSession",
    meta: createSessioniA85SgEpGGMeta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/dashboard/createSession.vue")
  },
  {
    name: "dashboard-home",
    path: "home",
    meta: home7laHID8UBJMeta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/dashboard/home.vue")
  },
  {
    name: "dashboard-sessions",
    path: "sessions",
    meta: sessionsqPhjnZecWeMeta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/dashboard/sessions.vue")
  }
]
  },
  {
    name: "experience-sessionId",
    path: "/experience/:sessionId()",
    meta: _91sessionId_93mzQM8YYZGpMeta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/experience/[sessionId].vue")
  },
  {
    name: "experience-end",
    path: "/experience/end",
    meta: endGb3hQ1WSeiMeta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/experience/end.vue")
  },
  {
    name: "experience-live-slug",
    path: "/experience/live/:slug(.*)*",
    meta: _91_46_46_46slug_93rbsNfDxkF0Meta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/experience/live/[...slug].vue")
  },
  {
    name: "experience-wait-slug",
    path: "/experience/wait/:slug(.*)*",
    meta: _91_46_46_46slug_93300SOODkVKMeta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/experience/wait/[...slug].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexTSAioG59l7Meta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginIqreHEt0VhMeta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/login.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingGiP6x1aXBaMeta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/pricing.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/privacy.vue")
  },
  {
    name: "reset-password-token",
    path: "/reset-password/:token()",
    meta: _91token_93qcLIpYXwdxMeta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/reset-password/[token].vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupz4EzOcAeGxMeta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/signup.vue")
  },
  {
    name: "systemCheck",
    path: "/systemCheck",
    meta: systemCheckOeanhVxuDKMeta || {},
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/systemCheck.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/terms.vue")
  },
  {
    name: "verify-slug",
    path: "/verify/:slug(.*)*",
    component: () => import("/codebuild/output/src1489521149/src/synergos-frontend/pages/verify/[...slug].vue")
  },
  {
    name: component_45stubYMP1BP7bQhMeta?.name,
    path: "/docs",
    component: component_45stubYMP1BP7bQh
  }
]