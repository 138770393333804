import { useAuthStore } from '~/stores/useAuthStore'
import { useRouter } from 'vue-router'

export const useLogout = () => {
  const auth = useAuthStore()
  const router = useRouter()
  
  /**
   * Clear cookies efficiently in batches
   */
  const clearCookies = () => {
    // Get all cookies
    const cookies = document.cookie.split(';')
    
    // Create a single string to clear all cookies at once
    const clearString = cookies
      .map(cookie => {
        const name = cookie.split('=')[0].trim()
        return `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`
      })
      .join(' ')
    
    // Set all cookies at once
    if (clearString) {
      document.cookie = clearString
    }
  }
  
  /**
   * Handles the complete logout process including cleanup and navigation
   */
  const logout = async () => {
    try {
      // First, navigate away from protected routes to prevent mounted hooks from firing
      await router.push('/')
      
      const storedToken = localStorage.getItem('token')
      
      // Call server to invalidate token if it exists (do this before clearing state)
      if (storedToken) {
        try {
          await useApiFetch('/api/logout', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${storedToken}`,
            }
          })
        } catch (error) {
          // Silently handle 401/419 errors as they might be expected during logout
          if (!(error instanceof Error) || (!error.message.includes('401') && !error.message.includes('419'))) {
            console.error('Logout API call failed:', error)
          }
        }
      }

      // Use requestAnimationFrame to batch DOM operations
      requestAnimationFrame(() => {
        // Clear all storage and state after API calls
        localStorage.removeItem('token')
        localStorage.removeItem('token_expiration')
        localStorage.removeItem('google_auth_state')
        localStorage.removeItem('facebook_auth_state')
        
        // Batch cookie clearing
        clearCookies()
      })
      
      // Reset auth store state
      auth.logout()

    } catch (error) {
      console.error('Error during logout cleanup:', error)
      // Ensure critical state is reset even if cleanup fails
      auth.logout()
      
      // Still try to navigate home if we haven't already
      if (router.currentRoute.value.path !== '/') {
        await router.push('/')
      }
    }
  }

  return {
    logout
  }
} 