import type { UseFetchOptions } from 'nuxt/app'
import type { NitroFetchOptions } from 'nitropack'
import { useLogout } from './useLogout'
import { useCsrfToken } from './useCsrfToken'
import { unref } from 'vue'

interface ApiError {
  response: {
    _data: {
      error: string;
      message?: string;
    };
    status: number;
  };
}

// Connection pool configuration
const POOL_SIZE = 6 // Maximum concurrent connections
const REQUEST_TIMEOUT = 30000 // 30 seconds
let activeConnections = 0
const requestQueue: Array<() => Promise<any>> = []

// Helper to process queue
const processQueue = async () => {
  if (requestQueue.length === 0 || activeConnections >= POOL_SIZE) return
  
  activeConnections++
  const request = requestQueue.shift()
  
  try {
    await request?.()
  } finally {
    activeConnections--
    processQueue() // Process next in queue
  }
}

export async function useApiFetch<T>(
  path: string | (() => string),
  options: Partial<UseFetchOptions<T>> = {}
): Promise<T> {
  const headers: HeadersInit = {}
  const config = useRuntimeConfig()
  const { fetchCsrfTokenIfNeeded } = useCsrfToken()

  // Only fetch CSRF token for mutations
  const method = unref(options.method) || 'GET'
  const requiresCsrf = ['POST', 'PUT', 'DELETE', 'PATCH'].includes(method)
  if (requiresCsrf) {
    await fetchCsrfTokenIfNeeded()
  }

  const xsrfToken = useCookie('XSRF-TOKEN')
  const sessionCookie = useCookie('laravel_session')

  if (xsrfToken.value) {
    headers['X-XSRF-TOKEN'] = decodeURIComponent(xsrfToken.value)
  }

  headers['Accept'] = 'application/json'
  // Only set Content-Type to application/json if not FormData
  if (!(options.body instanceof FormData)) {
    headers['Content-Type'] = 'application/json'
  }

  if (import.meta.server) {
    const requestHeaders = useRequestHeaders(['cookie', 'referer', 'host'])
    
    if (requestHeaders.cookie) {
      headers['cookie'] = requestHeaders.cookie
    }
    if (requestHeaders.referer) {
      headers['referer'] = requestHeaders.referer
    }
    if (requestHeaders.host) {
      headers['host'] = requestHeaders.host
    }
  }

  // Create the actual fetch request
  const performFetch = async (): Promise<T> => {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), REQUEST_TIMEOUT)

    try {
      const response = await $fetch<T>(config.public.apiBase + path, {
        credentials: 'include',
        watch: false,
        ...options,
        headers: {
          ...headers,
          ...(options.headers || {})
        },
        withCredentials: true,
        signal: controller.signal
      } as NitroFetchOptions<any>)

      return response as T
    } catch (error) {
      const err = error as ApiError
      
      if (err.response?._data) {
        const errorMessage = err.response._data.error || err.response._data.message
        console.error(`API Error (${err.response.status}):`, errorMessage)
        
        // Handle 401 Unauthenticated error
        if (err.response.status === 401 && errorMessage === 'Unauthenticated') {
          const { logout } = useLogout()
          await logout()
          await navigateTo('/login')
        }
        
        throw new Error(errorMessage ?? 'An unknown error occurred')
      }
      
      console.error('Network Error:', error)
      throw new Error('Unable to connect to the server.')
    } finally {
      clearTimeout(timeoutId)
    }
  }

  // If we're at connection limit, queue the request
  if (activeConnections >= POOL_SIZE) {
    return new Promise((resolve, reject) => {
      requestQueue.push(async () => {
        try {
          const result = await performFetch()
          resolve(result)
        } catch (error) {
          reject(error)
        }
      })
    })
  }

  // Otherwise execute immediately
  activeConnections++
  try {
    return await performFetch()
  } finally {
    activeConnections--
    processQueue() // Process next request in queue
  }
}